@import "variables.css";
@import "google.css";
@import "utils.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

h2 {
  margin: 0;
}

.reservation-table .table-header {
  align-items: end;
  padding: 6px 15px;
}

.reservation-table .table-header:nth-child(2) {
  text-align: right;
}

.reservation-table .table-row{
  align-items: start;
  padding: 0px 12px;
}

.reservation-table .table-row > div {
  align-items: start;
  padding: 15px 5px;
}

.reservation-table .table-row > div:nth-child(2) > p {
  text-align: right;
  padding: 0px 25px;
  width: 100%;
}
