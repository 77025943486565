@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Inter', 'Lucida Sans', 'Lucida Sans Regular',
    'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --faint-color: #0000000D;
  --faint-dark-color: #0000004D;

  --secondary-color: #1691DE;

  /* Default border radius that is used in most of the cases. */
  --border-radius: 8px;

  --font-size-2xlg: 2rem;
  --font-size-xlg: 1.5rem;
  --font-size-lg: 1.25rem;
  --font-size-md: 1rem;
  --font-size-s: 0.875rem;
  --font-size-xs: 0.75rem;
}

.pac-container {
  /* Overriding deault z-index to display it above MUI's modal which have 1300 z-index. */
  z-index: 1500;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

h2 {
  margin: 0;
}

.reservation-table .table-header {
  align-items: end;
  padding: 6px 15px;
}

.reservation-table .table-header:nth-child(2) {
  text-align: right;
}

.reservation-table .table-row{
  align-items: start;
  padding: 0px 12px;
}

.reservation-table .table-row > div {
  align-items: start;
  padding: 15px 5px;
}

.reservation-table .table-row > div:nth-child(2) > p {
  text-align: right;
  padding: 0px 25px;
  width: 100%;
}

