:root {
  --faint-color: #0000000D;
  --faint-dark-color: #0000004D;

  --secondary-color: #1691DE;

  /* Default border radius that is used in most of the cases. */
  --border-radius: 8px;

  --font-size-2xlg: 2rem;
  --font-size-xlg: 1.5rem;
  --font-size-lg: 1.25rem;
  --font-size-md: 1rem;
  --font-size-s: 0.875rem;
  --font-size-xs: 0.75rem;
}
